<script setup lang="ts">
defineProps<{
  // The file URL as a string.
  fileUrl?: string

  // The file name as a string or null.
  fileName?: string | null

  // The file size as a string or undefined.
  fileSize?: string
}>()

const emit = defineEmits<{
  (event: 'remove'): void
}>()
</script>

<template>
  <div
    class="border-muted-200 dark:border-muted-700 dark:bg-muted-800 relative flex items-center justify-between gap-2 rounded-xl border bg-white p-3"
  >
    <div class="flex items-center gap-2">
      <div class="shrink-0">
        <img
          v-if="fileUrl"
          class="size-14 rounded-xl object-cover object-center"
          :src="fileUrl"
          alt="Image preview"
        >

        <img
          v-else
          class="size-14 rounded-xl object-cover object-center"
          src="/img/avatars/placeholder-file.png"
          alt="Image preview"
        >
      </div>

      <div class="font-sans">
        <span
          v-if="fileName"
          class="text-muted-800 dark:text-muted-100 line-clamp-1 block text-sm"
        >
          {{ fileName }}
        </span>

        <span v-if="fileSize" class="text-muted-400 block text-xs">
          {{ fileSize }}
        </span>
      </div>
    </div>

    <div class="flex gap-2">
      <button
        class="border-muted-200 hover:border-primary-500 text-muted-700 dark:text-muted-200 hover:text-primary-600 dark:border-muted-700 dark:bg-muted-900 dark:hover:border-primary-500 dark:hover:text-primary-600 relative flex size-8 cursor-pointer items-center justify-center rounded-full border bg-white transition-colors duration-300"
        type="button"
        tooltip="Remove"
        @click.prevent="emit('remove')"
      >
        <Icon name="lucide:x" class="size-4" />
        <span class="sr-only">Remove</span>
      </button>
    </div>
  </div>
</template>
